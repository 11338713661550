<template>
  <VTable
    :head-row="headRow"
    :rows="rows"
    :is-checkbox="false"
    url-set-rows="/accreditation"
    :transform-object="transformObject"
    url-action="/users/action"
    url-action-query="search[Active]=active"
    @link="handlerLink"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import VTable from '@/components/ui/table/VTable'

const store = useStore()
const router = useRouter()
const headRow = [
  {
    name: 'Наименование',
    value: 'name',
    filter: true,
    decorator: {
      name: 'link'
    }
  },
  {
    name: 'Дата/время заявки',
    value: 'created_at',
    filter: true
  },
  {
    name: 'ФИО ответственного',
    value: 'fio',
    filter: true
  }
]

const rows = computed(() => {
  return store.getters['item/getItems']
})

const transformObject = (data) => {
  data.map((item) => {
    item.name = item.user.organization_name
    item.fio = item.user.name
    item.created_at = item.user.created_at.slice(0, 19).replace('T', ' ')
  })

  return data
}

const handlerLink = (item) => {
  router.push(`/security/accreditation/${item.item.id}`)
}

</script>
